import { Box, Grid, Typography } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

function Footer(){
    return(
        <Box sx={{ px: {md: 10, lg: 2}, py: { md: 10 }, backgroundColor: "#F9F9F9" }}>
            <Grid container spacing={3}>
                <Grid item md={6} sx={{ width: "100%", alignItems: "center" }}>
                    <Box sx={{ px: {xs: 5, sm: 5, md: 10} }}>
                        <Typography variant="h3">
                            VegaDevX
                        </Typography>
                        <Box  sx={{ mt: 2, mb: 2 }}>    
                            <Typography variant="body1" sx={{ textAlign: "justify" }}>
                                VegaDevX strives to bridge businesses and talents across the globe under one platform. 
                                Our services enable businesses to develop robust applications by hiring talents from our 
                                pre-screened and vetted pool of professionals.
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Box sx={{ px: {xs: 5, sm: 5, md: 0} }}>
                        <Typography variant="h5">
                            Quick Links
                        </Typography>      
                        <List>
                            <ListItem>
                                <a href="/about" style={{ textDecoration: "none", color: "black" }}>
                                    <Typography textAlign="center">About</Typography>
                                </a>                           
                            </ListItem>
                            <ListItem>
                                <a href="/services" style={{ textDecoration: "none", color: "black" }}>
                                    <Typography textAlign="center">Service</Typography>
                                </a>                          
                            </ListItem>
                            <ListItem>
                                <a href="/careers" style={{ textDecoration: "none", color: "black" }}>
                                    <Typography textAlign="center">Careers</Typography>
                                </a>                            
                            </ListItem>
                            <ListItem>
                                <a href="/contact" style={{ textDecoration: "none", color: "black" }}>
                                    <Typography textAlign="center">Contact</Typography>
                                </a>                            
                            </ListItem>
                        </List> 
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Box sx={{ px: {xs: 5, sm: 5, md: 0} }}>
                        <Typography variant="h5">
                            Contact
                        </Typography>
                        <List>
                            <ListItem>
                                Email
                            </ListItem>
                            <ListItem>
                                info@vegadevx.com                           
                            </ListItem>
                            <ListItem>
                                Number                 
                            </ListItem>
                            <ListItem>
                                +1 (469) 759-3637                        
                            </ListItem>
                            <ListItem>
                                Location             
                            </ListItem>
                            <ListItem>
                                Fremont, California, USA                       
                            </ListItem>
                        </List>       
                    </Box>
                </Grid>
            </Grid>
        </Box>   
    )
}

export default Footer