import ServicePartTime from "../../assets/images/service_partime.svg"
import ServiceFullTime from "../../assets/images/service_fulltime.svg"
import ServiceProjectRecovery from "../../assets/images/service_project_recovery.svg"
import ServiceRemote from "../../assets/images/service_remote.svg"


const services = [
    {
        "name": "End to End Solution Development", 
        "description": "Transforming Ideas into Impact: From initial concept to final delivery, VegaDevX manages your project with unmatched agility, ensuring seamless execution every step of the way.",
        "image": ServiceProjectRecovery
    },
    {
        "name": "ODC-IDC - India Center Bangalore",
        "description": "Global Innovation, Local Expertise: VegaDevX's Bangalore center harnesses top talent to deliver cutting-edge solutions, blending international innovation with local insight.",
        "image": ServiceRemote
    },
    {
        "name": "Time & Material based Development Services", 
        "description": "Unleash Top Talent: Access VegaDevX's elite professionals on your terms, offering top-tier talent for onsite and offshore projects, under your management for unmatched flexibility.",
        "image": ServicePartTime
    },
    {
        "name": "Contract Services - SLA Level Services", 
        "description": "Excellence Guaranteed: Our SLA-backed services promise unparalleled quality and reliability, meeting your needs with precision and integrity.",
        "image": ServiceFullTime
    },
]

export default services