import abouthero from '../../assets/images/abouthero.jpg'
import ideas from '../../assets/images/ideas.jpg'
import contact from '../../assets/images/contact.jpg'


const slider1Elements = [
    {
        "title": "Your global IT solutions partner.",
        "content": " Our mission is to expedite your path from idea to reality, leveraging a globally distributed team of highly skilled engineers.",
        "link": "/about",
        "image": abouthero
    },
    {
        "title": "Transforming Ideas into Reality",
        "content": "At VegaDevX, we believe that every great product begins with a great idea. Our talented team of engineers will help you develop your ideas by asking relevant questions and by providing domain-specific insights. We'll work closely with you to refine your vision and help you convert your ideas into a successful product.",
        "link": "/services",
        "image": ideas
    },
    {
        "title": "Contact us",
        "content": "Let's Chat! Your ideas and feedback light up our world. Drop us a line, share your thoughts, and let's create something amazing together. We're all ears!",
        "link": "/contact",
        "image": contact
    }
]

export default slider1Elements