import './App.css';
import Homepage from './pages/homepage';
import Services from './pages/services';
import Talent from './pages/talent';
import AboutUs from './pages/about';
import ContactPage from './pages/contact';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import Footer from './components/footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar';
import Careers from './pages/careers';

function App() {
  return (
    <ThemeProvider theme={theme}>
          <Navbar />
        <Router>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/services" element={<Services />} />
            {/* <Route path="/clients" element={<Clients />} /> */}
            <Route path="/talent" element={<Talent />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/careers" element={<Careers />} />
          </Routes>
        </Router>
        {/* <Insights /> */}
        <Footer />
    </ThemeProvider>
  );
}

export default App;
