import { Box, Typography } from "@mui/material";
import Container from '@mui/material/Container';
import { renderGrid } from "../general/renderGrid";
import services from "../../assets/dataSets/services"

function Banner(){
    return(
        <Container maxWidth="xl">
            <Box sx={{ mt: "15vh" }}>
                <center>
                    <Typography variant="h3">
                        Services
                    </Typography>
                </center>
            </Box>
            <Box sx={{ mt: "5vh" }}>
                {renderGrid(services)}
            </Box>
        </Container>

    )
}

export default Banner