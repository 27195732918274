import TalentIntro from "../../components/talent/talentIntro";
import { Box } from "@mui/material";
import TalentBanner from "../../assets/images/talent_banner.png"

function Talent(){
    return(
        <Box sx={{ py: {xs: 2, sm: 2, md: 10}, px: {xs: 1, sm: 1, md: 10} }}>
            <TalentIntro />
            <Box sx={{ mt: 5 }}>
                <img src={TalentBanner} alt="" width="100%" />
            </Box>
        </Box>
    )
}

export default Talent