// import aboutPageData from "../../assets/dataSets/servicePageData";
import aboutPageData from "../../assets/dataSets/about";
import React from "react";
import { Box, Typography } from "@mui/material";
import { Card, CardContent, Grid } from "@mui/material";

function AboutCarousel(){
    return(
        <Box>
           <Box sx={{ mt: 5 }}>
                <center>
                    <Box sx={{ pt: 2, mb: 3 }}>
                        <Typography variant="h3">
                            About Us
                        </Typography>
                    </Box>
                </center>
            </Box>
            <Grid container alignItems="center" spacing={2}>
                {aboutPageData.map((item, index) => (
                    <Grid item sm={12} md={4}>
                        <Card key={index} style={{ borderRadius: "20px", width: "100%"}} sx={{ '&:hover': {scale: '1.04'} }}>
                            <CardContent>
                                <center>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {item.name}
                                    </Typography>
                                </center>
                                {/* <center> */}
                                    <Box sx={{ p: 2, textAlign: "justify" }}>
                                        <Typography variant="body2" color="text.secondary">
                                            {item.description}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <img src={item.image} width="100%" height="100%" alt={`services-${item.name}`} />
                                    </Box>
                                {/* </center> */}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}


export default AboutCarousel