// import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Typography } from '@mui/material'
import slider1Elements from "../../assets/dataSets/slider1";
import Carousel from 'react-material-ui-carousel'
// import hero from '../../assets/images/hero.jpg'


function Slider(){
    return(
        <Carousel height="100vh">
        {
            slider1Elements.map( (item, i) =>
                <Box 
                    sx={{
                        flexgrow: 1, 
                        height: {
                            xs: "100%", 
                            sm: "100%", 
                            md: "100%", 
                            lg: "100%", 
                            xl: "100%"}, 
                        width: "100%",
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${item.image})`, 
                        backgroundSize: "cover",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                    key={i}
                >
                    <Box sx={{ color: "white" }}>
                        <a href={item.link} style={{ textDecoration: "none", color: "white" }}>
                            <center>
                            <Box>
                                <Typography variant="h2">
                                    {item.title}
                                </Typography>
                            </Box>
                            </center>
                            <Box sx={{ mt: 5, px: "20%" }}>
                                <Typography variant="h6">
                                    {item.content}
                                </Typography>
                            </Box>
                            {/* <Box>
                                <Button variant="outlined" sx={{ mt: 3, borderColor: "white", color: "white",  "&:hover": {borderColor: "white"} }} href={item.link}>
                                    Learn More
                                </Button>
                            </Box> */}
                            </a>
                    </Box>
                </Box>
        )
        }
        </Carousel>
    )
}

export default Slider