// import Banner from "../../components/services/banner"
import ServiceHero from "../../components/services/hero"
import ServiceGrid from "../../components/services/grid"
import { Box } from "@mui/material"

function Services(){
    return(
        <Box sx={{ mb: 2 }}>
            <ServiceHero />
            <ServiceGrid />
            {/* <Banner />w */}
        </Box>
    )
}

export default Services