import About from "../../assets/images/about.jpeg"
import Approach from "../../assets/images/approach.jpeg"
import Security from "../../assets/images/leader.jpeg"



const aboutPageData = [
    {
        "name": "How we work",
        "description": "At VegaDevX, We pride ourselves in being a leading engineering solutions provider. With a group of senior engineers with proven track records in the industry, we deliver quality engineering services at a reasonable price.",
        "description2": "At VegaDevX, We pride ourselves in being a leading engineering solutions provider. With a group of senior engineers with proven track records in the industry, we deliver quality engineering services at a reasonable price.",
        "image": About
    }, 
    {
        "name": "Our Approach",
        "image": Approach,
        "description": "We believe in an on-site/off-shore hybrid model. We combine onsite lead engineers, project managers with a globally distributed developers. This approach allows us to offer cost-effective solutions without compromising on quality and efficiency.",
        "description2": "We believe in an on-site/off-shore hybrid model. We combine onsite lead engineers, project managers with a globally distributed developers. This approach allows us to offer cost-effective solutions without compromising on quality and efficiency.",

    },
    {
        "name": "Industry Leadership",
        "image": Security,
        "description": "Our track record includes successful projects and satisfied clients in the biometrics, medical, security, peripheral connectivity and IoT sectors. We push boundaries to innovate and to be an effective solution provider and partner.",
        "description2": "Our track record includes successful projects and satisfied clients in the biometrics, medical, security, peripheral connectivity and IoT sectors. We push boundaries to innovate and to be an effective solution provider and partner."
    }
]

export default aboutPageData