export const talent = {
    "title": "Our Talent",
    "content": "Bringing the right and talented resources onboard determines the success and growth of a business for any industry. VegaDevX is a powerhouse packed with avant-garde development experts. In addition, we also enable talents across the globe to meet the right clients. VegaDevX strives to spot and create a unique, passionate pool of freelancers across the globe to enable business ventures to reach their requirements and goals. With industry-experienced experts on our team, businesses no longer have to worry about hiring the right fit for their tech scale-ups. VegaDevX helps global brains connect with businesses in look out for talents to kick-start their project or venture. Moreover, VegaDevX greatly aids businesses in reducing the time, effort, and money invested in the hiring process. On the flip side, we ease the process for professionals to land in the right organization."
}

export const clientIntro = {
    "title": "Our Clients",
    "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui vivamus arcu felis bibendum ut tristique. Sit amet nulla facilisi morbi tempus iaculis. Molestie a iaculis at erat. Mauris vitae ultricies leo integer malesuada nunc vel. Elementum nisi quis eleifend quam adipiscing vitae. At quis risus sed vulputate odio ut enim. Felis bibendum ut tristique et egestas quis ipsum suspendisse ultrices.  Lacinia at quis risus sed vulputate odio ut enim. Lacus luctus accumsan tortor posuere ac ut. Sed lectus vestibulum mattis ullamcorper. Porta lorem mollis aliquam ut porttitor leo. Massa tincidunt nunc pulvinar sapien."
}

export const serviceIntro = {
    "title": "Transforming Ideas into Reality",
    "content": "At VegaDevX, we believe that every great product begins with a strong idea. Our dedicated team of engineers will help you develop your concept by asking relevant questions and providing domain-specific insights. We'll work closely with you to refine your vision and create a solid foundation for your project's success."
}

export const aboutIntro = {
    "title": "About Us",
    "content": "At VegaDevX, we pride ourselves on being a leading engineering solutions provider. With a group of senior engineers boasting proven track records in the industry, we deliver quality engineering services at a reasonable price."
}


export const aboutApproach = {
    "title": "Our Approach",
    "content": "We believe in an off-shore hybrid model, combining localized project management with a globally distributed workforce. This approach allows us to offer cost-effective solutions without compromising on quality and efficiency."
}


export const aboutLeadership = {
    "title": "Industry Leadership",
    "content": "At VegaDevX, we stand out as industry leaders. Our track record includes successful projects and satisfied clients in the biometrics, medical, IoT, and security sectors. We are proven innovators, continually pushing boundaries and exploring new horizons."
}
