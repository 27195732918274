import Container from '@mui/material/Container';
import { Box } from '@mui/material';
import aboutPageData from "../../assets/dataSets/about";
import { renderHalfGrid } from "../general/renderGrid";

function AboutGrid(){
    const isSmallScreen = window.innerWidth < 768;

    return(
        <Container maxWidth="xl">
            <Box>
                {aboutPageData.map((detail, index) =>
                    renderHalfGrid(detail, index, isSmallScreen)
                )}
            </Box>
        </Container>
    )
}

export default AboutGrid