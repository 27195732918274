const listings = [
    {
        name: ".NET Developer",
        jobType: "Full Time",
        location: "Anywhere"
    },
    {
        name: "C++",
        jobType: "Full Time",
        location: "Anywhere"
    },
    {
        name: "Driver Engineers",
        jobType: "Full Time",
        location: "Anywhere"
    }
]

export default listings