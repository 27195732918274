import Container from '@mui/material/Container';
import { Box, Typography } from '@mui/material';
import servicePageData from "../../assets/dataSets/servicePageData";
import originalServices from "../../assets/dataSets/originalServices";
import { renderHalfGrid } from "../general/renderGrid";
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';


function ServiceGrid(){
    const isSmallScreen = window.innerWidth < 768;

    const navigate = useNavigate();

    useEffect(() => {
        const hash = window.location.hash;
    
        if (hash) {
          const targetElement = document.getElementById(hash.substring(1));
    
          if (targetElement) {
            targetElement.scrollIntoView({
              behavior: 'smooth',
            //   block: 'center', // Center vertically
            //   inline: 'center', // Center horizontally
            });
    
            // Adjust the scroll position by subtracting 10 pixels
            // window.scrollBy(0, -10);
          }
        }
      }, [navigate]);
    return(
        <Container maxWidth="xl">
            <Box sx={{ mt: "15vh" }}>
                <center>
                    <Typography variant="h3">
                        Services
                    </Typography>
                </center>
            </Box>
            <Box>
                {originalServices.map((detail, index) =>
                    <Box id={detail.id} key={index}>
                        {renderHalfGrid(detail, index, isSmallScreen)}
                    </Box>
                )}
            </Box>
            <Box sx={{ mt: "15vh" }}>
                <center>
                    <Typography variant="h3">
                        Industry Focus
                    </Typography>
                </center>
            </Box>
            <Box>
                {servicePageData.map((detail, index) =>
                    <Box id={detail.id} key={index}>
                        {renderHalfGrid(detail, index, isSmallScreen)}
                    </Box>
                )}
            </Box>
        </Container>
    )
}

export default ServiceGrid