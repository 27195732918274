import { Box } from "@mui/material";
import Banner from "../../components/services/banner"
import AboutGrid from "../../components/about/grid";
import AboutHero from "../../components/about/hero";


function AboutUs(){
    return(
        <Box>
            <AboutHero />
            <AboutGrid />
            <Banner />
        </Box>
    )
}

export default AboutUs