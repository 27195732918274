import { Box, Container, Grid, Typography } from "@mui/material";

export function renderGrid(details) {
  return (
    <Grid container spacing={{ xs: 1, sm: 1, md: 2, lg: 5, xl: 5 }}>
      {renderGridItems(details)}
    </Grid>
  );
}

export function renderHalfGrid(detail, index, isSmallScreen = false) {
  if (index % 2 === 0 || isSmallScreen) {
    return (
			<Box sx={{ mt: "15vh", mb: "15vh" }} key={index}>
				<Grid container key={index}>
					<Grid item sm={12} md={6}>
						<img src={detail.image} alt="" width="100%" />
					</Grid>
					<Grid item sm={12} md={6}>
						<Box 
								sx={{
											display: "flex", 
											direction: "column", 
											alignItems: "center", 
											justifyContent: "center", 
											minHeight: "100%"
										}}
						>
							<Box>
								<Container>
									<Box> 
										<Typography variant="h3">{detail.name}</Typography>
									</Box>
									<Box sx={{ mt: 2 }}>
										<Typography variant="h6">{detail.description}</Typography>
									</Box>
									<Box sx={{ mt: 2 }}>
											<Typography variant="body1">{detail.description2}</Typography>
									</Box>
									<Box sx={{ mt: 2 }}> 
											<Typography variant="body1">{detail.description3}</Typography>
									</Box>
									<Box sx={{ mt: 2 }}> 
											<Typography variant="body1">{detail.description4}</Typography>
									</Box>
									<Box sx={{ mt: 2 }}> 
											<Typography variant="body1">{detail.description5}</Typography>
									</Box>
									<Box sx={{ mt: 2 }}> 
											<Typography variant="body1">{detail.description6}</Typography>
									</Box>
								</Container>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Box>
    );
  }else{
    return(
			<Box sx={{ mt: "15vh", mb: "15vh" }} key={index}>
        <Grid container key={index}>
            <Grid item sm={12} md={6}>
							<Box 
									sx={{
												display: "flex", 
												direction: "column", 
												alignItems: "center", 
												justifyContent: "center", 
												minHeight: "100%"
											}}
							>
								<Box>
									<Container >
										<Box> 
											<Typography variant="h3">{detail.name}</Typography>
										</Box>
										<Box sx={{ mt: 2 }}>
											<Typography variant="h6">{detail.description}</Typography>
										</Box>
										<Box sx={{ mt: 2 }}>
												<Typography variant="body1">{detail.description2}</Typography>
										</Box>
										<Box sx={{ mt: 2 }}> 
												<Typography variant="body1">{detail.description3}</Typography>
										</Box>
                    <Box sx={{ mt: 2 }}> 
											<Typography variant="body1">{detail.description4}</Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}> 
                        <Typography variant="body1">{detail.description5}</Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}> 
                        <Typography variant="body1">{detail.description6}</Typography>
                    </Box>
									</Container>
								</Box>
							</Box>
            </Grid>

            <Grid item sm={12} md={6}>
            	<img src={detail.image} alt="" width="100%" />
            </Grid>
        </Grid>
			</Box>
    )
  }
}

export function renderFiveGrid(details) {
  const slicedFrontArray = details.slice(0, 3);
  const slicedBackArray = details.slice(3, 5);
  return (
    <Box>
      <Grid container spacing={{ xs: 1, sm: 1, md: 2, lg: 5, xl: 5 }}>
        {renderFiveGridItems(slicedFrontArray)}
      </Grid>
      <Grid container sx={{ mt: 5 }}>
        {renderCenterGridItems(slicedBackArray)}
      </Grid>
    </Box>
  );
}

export function renderServiceDetails(details) {
  return <Box>{renderServiceDescription(details)}</Box>;
}

export function renderIntro(title, content) {
  return (
    <Box>
      <Typography variant="h3">{title}</Typography>
      <Box sx={{ mt: 2, mb: 5 }}>
        <Typography variant="h6">{content}</Typography>
      </Box>
    </Box>
  );
}

function renderGridItems(details) {
  return details.map((detail, index) => (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={index} sx={{ mt: 5 }}>
      <Box >
        <center>
          <img src={detail.image} alt="" width="60%" />
					<Box sx={{ mt: 2 }}>
          	<Typography variant="h6">{detail.name}</Typography>
            <br/>
            <Box sx={{ px: 5 }}>
          	  <Typography variant="p">{detail.description}</Typography>
            </Box>
					</Box>
          <br/><br/>
        </center>
      </Box>
    </Grid>
  ));
}

function renderFiveGridItems(details) {
  return details.map((detail, index) => (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={index}>
      <Box>
        <center>
          <img src={detail.image} alt="" width="100%" />
          <Typography variant="h6">{detail.name}</Typography>
        </center>
      </Box>
    </Grid>
  ));
}

function renderCenterGridItems(details) {
  return details.map((detail, index) => (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={index}>
      <center>
        <Box>
          <center>
            <img src={detail.image} alt="" width="100%" />
            <Typography variant="h6">{detail.name}</Typography>
          </center>
        </Box>
      </center>
    </Grid>
  ));
}

function renderServiceDescription(details) {
  return details.map((detail, index) => (
    <Box sx={{ mb: 8 }} key={index}>
      <Typography variant="h4">{detail.name}</Typography>
      {renderDescription(detail.description)}
      {renderDescription(detail.description2)}
      {renderDescription(detail.description3)}
      {renderDescription(detail.description4)}
      {renderDescription(detail.description5)}
      {renderDescription(detail.description6)}
      <a href="/contact" style={{ textDecoration: "none", color: "black" }}>
        <Typography variant="h6">Request now -&gt;</Typography>
      </a>
      <Box sx={{ mt: 10 }}>
        <hr />
      </Box>
    </Box>
  ));
}

function renderDescription(description) {
  if (!description) {
    return false;
  } else {
    return (
      <Box sx={{ mt: 2, mb: 3 }}>
        <Typography variant="body1">{description}</Typography>
      </Box>
    );
  }
}
