import { Box } from "@mui/material";
import ContactComponent from "../../components/contact/contactConponent";

function ContactPage(){
    return(
        <Box>
            <ContactComponent />
        </Box>
    )
}

export default ContactPage