import services from "../../assets/dataSets/servicePageData";
import servicePageData from "../../assets/dataSets/originalServices";
import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Navleft from "../../assets/images/nav_left.svg"
import NavRight from "../../assets/images/nav_right.svg"

function ServiceDataCarousel(){

    const isSmallScreen = window.innerWidth < 768;

    const [activeIndex, setActiveIndex] = useState(() => {
        const storedActiveIndex = localStorage.getItem('activeIndex');
        return storedActiveIndex ? parseInt(storedActiveIndex, 10) : 0;
      });

    const allServices = servicePageData.concat(services)

    // console.log(allServices)

    const handleNext = () => {

        setActiveIndex(prevIndex => {
            let newCount = 0
            if(prevIndex === 3){
                newCount = 0
            }else{
                newCount =  (prevIndex + 1) % allServices.length
            }
            localStorage.setItem('activeIndex', newCount);
            return newCount;
          });
        // if(activeIndex === 2){
        //     setActiveIndex(0)
        //     localStorage.setItem('activeIndex', 0);
        // }else{
        //     setActiveIndex((prevIndex) => (prevIndex + 1) % allServices.length);
        //     localStorage.setItem('activeIndex', activeIndex);
        // }
      };
    
    const handlePrev = () => {
        setActiveIndex(prevIndex => {
            let newCount = 0
            if(prevIndex === 0){
                newCount = 2
            }else{
                newCount =  (prevIndex - 1) % allServices.length
            }
            localStorage.setItem('activeIndex', newCount);
            return newCount;
          });
        // if(activeIndex === 0){
        //     setActiveIndex(2)
        //     localStorage.setItem('activeIndex', 2);
        // }else{
        //     setActiveIndex((prevIndex) => (prevIndex - 1 + allServices.length) % allServices.length);
        //     localStorage.setItem('activeIndex', activeIndex);
        // }
    };

    useEffect(() => {
        const interval = setInterval(() => {
          setActiveIndex(prevIndex => {
            let newCount = 0
            if(prevIndex === 3){
                newCount = 0
            }else{
                newCount =  (prevIndex + 1) % allServices.length
            }
            localStorage.setItem('activeIndex', newCount);
            return newCount;
          });
        }, 5000);
    
        return () => clearInterval(interval);
      }, [allServices.length]);
    

    const renderCardForSmallerScreen = (item) => {
        return(
            <Card sx={{ width: "90%", borderRadius: "20px" }}>
                <CardContent>
                    <center>
                        <Typography gutterBottom variant="h5" component="div">
                            {item.name}
                        </Typography>
                    </center>
                    <center>
                        <Box sx={{ p: 2 }}>
                            <Typography variant="body2" color="text.secondary">
                                {item.description}
                            </Typography>
                        </Box>
                        <Box>
                            <img src={item.image} width="100%" alt={`services-${item.name}`} />
                        </Box>
                    </center>
                </CardContent>
            </Card>
        )
    }


    if(!isSmallScreen){
        return(
            <Box>
                <Box sx={{ mt: 5 }}>
                        <center>
                            <Box sx={{ pt: 2 }}>
                                <Typography variant="h3">
                                    Our Services
                                </Typography>
                            </Box>
                        </center>
                    </Box>
                <Grid container justifyContent="center" spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                        <Box display="flex" flexDirection="row" gap="2vh">
                            {allServices.slice(activeIndex, activeIndex + 3).map((item, index) => (
                                <Card key={index} style={{ borderRadius: "20px", width: "100%", border: "1px solid #C5C5C5", height: `${index === activeIndex ? "100%" : "80%"}`}} sx={{ '&:hover': {scale: '1.04'} }}>
                                    <a href={`/services#${item.id}`} style={{textDecoration: "none"}}>
                                        <CardContent>
                                            <center>
                                                <Typography gutterBottom variant="h5" component="div" color="black">
                                                    {item.name}
                                                </Typography>
                                            </center>
                                            <center>
                                                <Box sx={{ p: 2 }}>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {item.description}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <img src={item.image} width="100%" height="100%" alt={`services-${item.name}`} />
                                                </Box>
                                            </center>
                                        </CardContent>
                                    </a>
                                </Card>
                            ))}
                        </Box>
                        <Box sx={{ mb: 3, mt: 5 }}>
                            <center>
                                <Button variant="dark" sx={{pl: 0}} onClick={handlePrev}>
                                    <img src={Navleft} alt="" height="40vh" />
                                </Button>
                                <Button variant="dark" sx={{pl: 0}}  onClick={handleNext}>
                                    <img src={NavRight} alt="" height="40vh"/>
                                </Button>
                            
                            </center>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        )
    }else{
        return(
            <Box sx={{ mb: 5 }}>
                <Box sx={{ mt: 5 }}>
                    <center>
                        <Box sx={{ pt: 2 }}>
                            <Typography variant="h3">
                                Our Services
                            </Typography>
                        </Box>
                    </center>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Grid container>
                        {services.map((item, index) => (
                            <Grid item key={index} sx={{ mt: 2}} xs={12} sm={12} md={12}>
                                <Box key={index}>
                                    <center>
                                        {renderCardForSmallerScreen(item)}
                                    </center>
                                </Box>    
                            </Grid>    
                        ))}
                    </Grid>
                </Box>
            </Box>
        )
    }
    
}

export default ServiceDataCarousel