import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        h1: {
            '@media (max-width:480px)': {
                fontSize: '2.5rem',
            },
            '@media (min-width: 481px) and (max-width: 768px)': {
                fontSize: '3.0rem',
            },
            '@media (min-width: 768px) and (max-width: 1024px)': {
                fontSize: '3.5rem',
            },
            '@media (min-width: 1025px) and (max-width: 1650px)': {
                fontSize: '4.0rem',
            },
        },
        h4: {
            '@media (max-width:480px)': {
                fontSize: '1.5rem',
            },
            '@media (min-width: 481px) and (max-width: 768px)': {
                fontSize: '2.0rem',
            },
            '@media (min-width: 768px) and (max-width: 1024px)': {
                fontSize: '2.0rem',
            },
            '@media (min-width: 1025px) and (max-width: 1650px)': {
                fontSize: '2.0rem',
            },
        },
        h3: {
            '@media (max-width:480px)': {
                fontSize: '2.0rem',
            },
            '@media (min-width: 481px) and (max-width: 768px)': {
                fontSize: '1.0rem',
            },
            '@media (min-width: 768px) and (max-width: 1024px)': {
                fontSize: '1.5rem',
            },
            '@media (min-width: 1025px) and (max-width: 1650px)': {
                fontSize: '2.0rem',
            }, 
        }
    }
});


export default theme