import { Box } from "@mui/system"
// import Hero from "../../components/homepage/hero"
// import Mission from "../../components/homepage/mission"
import ServiceDataCarousel from "../../components/homepage/servicecarosuel"
import AboutCarousel from "../../components/homepage/aboutCarousle";
// import React, { useRef, useState, useEffect } from 'react';
// import Paper from '@mui/material/Paper';
import Slider from "../../components/homepage/slider";
// import SliderServices from "../../components/homepage/sliderServices";
// import { Typography } from "@mui/material";

function Homepage(){
  return(
    <Box>
      <Box>
        <Slider />
      </Box>      
        {/* <SliderServices /> */}
      <ServiceDataCarousel />
      <Box sx={{ mt: 5 }}>
        <AboutCarousel />
      </Box>
    </Box>
  )
}

// function Homepage(){
//     const boxRefs = {
//         box1: useRef(null),
//         box2: useRef(null),
//         box3: useRef(null),
//       };

//       const [activeBox, setActiveBox] = useState('box1');
//       const [scrolling, setScrolling] = useState(false);    
    
//       useEffect(() => {

//         const handleScroll = (e) => {
//           const activeBoxRef = boxRefs[activeBox].current;
//           if (!scrolling) {
//               if (activeBoxRef && activeBoxRef.contains(e.target)){
//                   if (e.deltaY !== 0) {
//                       if (e.deltaY > 0) {
//                         // Scrolling down, move to the next box
//                         if (activeBox === 'box1') {
//                           e.preventDefault();
//                           setActiveBox('box2');
//                           boxRefs.box2.current.scrollIntoView({ behavior: 'smooth' });
//                         } else if (activeBox === 'box2') {
//                           e.preventDefault();
//                           setActiveBox('box3');
//                           boxRefs.box3.current.scrollIntoView({ behavior: 'smooth' });
//                         }
//                         else if (activeBox === 'box3') {
          
//                         }
//                       } else {
//                         // Scrolling up, move to the previous box
//                         if (activeBox === 'box3') {
//                           e.preventDefault();
//                           setActiveBox('box2');
//                           boxRefs.box2.current.scrollIntoView({ behavior: 'smooth' });
//                         } else if (activeBox === 'box2') {
//                           e.preventDefault();
//                           setActiveBox('box1');
//                           boxRefs.box1.current.scrollIntoView({ behavior: 'smooth' });
//                         }
//                       }
//                   }
//               }
  
//               setTimeout(() => {
//                   setScrolling(false);
//               }, 10000);
//           }
  
//         };

//         window.addEventListener('wheel', handleScroll, { passive: false });
//         window.addEventListener('scroll', handleScroll, { passive: false });
//         return () => {
//           window.removeEventListener('wheel', handleScroll);
//           window.removeEventListener('scroll', handleScroll);
//         };
//       });

//     return(
//         <Box>
//             <Paper ref={boxRefs.box1} elevation={3} id="box1" className="scroll-box">
//                 <Hero />
//             </Paper>
//             <Paper ref={boxRefs.box2} elevation={3} id="box2" className="scroll-box">
//                 <Mission />
//             </Paper>
//             <Paper ref={boxRefs.box3} elevation={3} id="box3" className="scroll-box">
//                 <ServiceDataCarousel />
//             </Paper>
//             {/* <Testimonial /> */}
//         </Box>
//     )
// }

export default Homepage