import Box from "@mui/system/Box";
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Container} from "@mui/system";
import ideas from '../../assets/images/ideas.jpg'

function ServiceHero(props){
    const theme = useTheme()

    return(
        <Box 
            sx={{
                flexgrow: 1, 
                height: {
                    xs: "100vh", 
                    sm: "100vh", 
                    md: "100vh", 
                    lg: "100vh", 
                    xl: "100vh"}, 
                width: "100%",
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${ideas})`, 
                backgroundSize: "cover",
                display: "flex",
                alignItems: "center"
            }}
        >
            <Container>
                <Box sx={{ color: "white" }}>
                    <Box>
                        <Typography variant="h1" theme={theme} >
                            Transforming Ideas into Reality
                        </Typography>
                    </Box>
                    <Box sx={{ mb: {xs: 2, md: 5} }}>
                        <Typography variant="body1" theme={theme}>
                            At VegaDevX, we believe that every great product begins with a great idea. Our talented team of 
                            engineers will help you develop your ideas by asking relevant questions and 
                            by providing domain-specific insights. We'll work closely with you to refine 
                            your vision and help you convert your ideas into a successful product.
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default ServiceHero
