import { Box, Typography, Grid } from "@mui/material";
import Email from "../../assets/images/email.svg"
import Call from "../../assets/images/call.svg"
import Location from "../../assets/images/location.svg"
import { TextField, Button } from "@mui/material";

function ContactComponent(){
    return(
        <Box sx={{ py: {xs: 2, sm: 2, md: 5}, px: {xs: 1, sm: 1, md: 10} }} >
            <Grid container>
                <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h3">
                        Schedule a meeting!
                    </Typography>
                    <Box>
                        <Box sx={{ mt: 5 }}>
                            <Grid container sx={{ height: "10vh" }}>
                                <Grid item sm={1} >
                                    <img src={Email} alt="" />
                                </Grid>
                                <Grid item sm={11} sx={{ pt: 1 }}>
                                    <Typography variant="h4">
                                        &nbsp; Email
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography variant="h6">
                                vegadevx@gmail.com
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 5 }}>
                            <Grid container sx={{ height: "10vh" }}>
                                <Grid item sm={1} >
                                    <img src={Call} alt="" />
                                </Grid>
                                <Grid item sm={11} sx={{ pt: 1 }}>
                                    <Typography variant="h4">
                                        Phone
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography variant="h6">
                                +1 (469) 759-3637
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 5 }}>
                            <Grid container sx={{ height: "10vh" }}>
                                <Grid item sm={1} >
                                    <img src={Location} alt="" />
                                </Grid>
                                <Grid item sm={11} sx={{ pt: 1 }}>
                                    <Typography variant="h4">
                                        Locations
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography variant="h6">
                                 Fremont, California
                            </Typography>
                            <Typography variant="h6">
                                Banglore, India
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Box sx={{ p: 10, backgroundColor: "#F9F9F9" }}>
                        <Box>
                            <Typography variant="h3">
                                Request your query here
                            </Typography>
                        </Box>
                        <Box component="form" noValidate sx={{ mt: 10 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Full Name"
                                name="name"
                                autoFocus
                            />
                            <Box sx={{ mt: 3 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="email"
                                    label="Email"
                                    type="email"
                                    id="email"
                                />
                            </Box>
                            <Box sx={{ mt: 3 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="phone"
                                    label="Mobile Number"
                                    name="mobile"
                                    autoFocus
                                />
                            </Box>
                            <Box sx={{ mt: 3 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="message"
                                    label="Message"
                                    name="message"
                                    multiline={true}
                                    rows={3}
                                    autoFocus
                                />
                            </Box>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2, height: "8vh"}}
                                style={{ backgroundColor: "#FFB52E" }}
                            >
                                Send Message
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ContactComponent