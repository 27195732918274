import Software from "../../assets/images/software.jpeg"
import Electrical from "../../assets/images/hardware_engineering.jpeg"
import Mechanical from "../../assets/images/electrical_engineering.jpeg"


const originalService = [
    {
        "name": "Software Development", 
        "title": "Software Development",
        "id": "softwareDevelopment",
        "content": "Empowering Your Devices with Intelligent Software",
        "description": "Empowering Your Devices with Intelligent Software",
        "description2": "Application: From desktop software to mobile apps, we craft intuitive and user-friendly applications that deliver a superior user experience.",
        "description3": "SDK: Our Software Development Kits (SDKs) empower developers to build applications that leverage your product's capabilities, facilitating third-party integration and expandability.",
        "description4": "Driver: We create custom device drivers that ensure seamless integration and communication between hardware components and software applications.",
        "description5": "Firmware: Our software experts develop efficient and reliable firmware solutions for your embedded systems, enhancing performance and security.",
        "description6": "Production: Efficiency and Quality at Scale",
        "image": Software
    },
    {
        "name": "Hardware Engineering",
        "title": "Electrical Engineering",
        "id": "electricalEngineering",
        "content": "Driving Innovation through Technology",
        "description": "Driving Innovation through Technology",
        "description2": " Design: Our hardware design process focuses on innovating and optimizing product architecture. We meticulously craft designs that balance performance, cost, and manufacturability, ensuring that the end product not only meets but exceeds market expectations.",
        "description3": "Schematics: We develop detailed schematics that serve as the blueprint for your product's hardware. This critical phase involves defining the electrical circuitry and interconnections, ensuring that each component works together harmoniously for peak performance.",
        "description4": "Layout Design: Our layout design process transforms schematics into a physical board layout, optimizing the placement of components and routing of electrical paths to minimize interference, reduce power consumption, and enhance product reliability and scalability.",
        "image": Electrical
    },
    {
        "name": "Contract Manufacturing",
        "title": "Contract Manufacturing",
        "id": "mechanicalEngineering",
        "content": "Precision and Perfection in Design",
        "description": "Precision and Perfection in Design",
        "description2": "Printed Circuit Board (PCB): Producing high-quality, durable PCBs with precision, adhering to rigorous standards.",
        "description3": "Assembly: Combining automated and manual techniques for precise component placement and secure soldering, accommodating various product complexities.",
        "description4": "Testing: Conducting thorough testing on components and final assemblies to uncover issues, guaranteeing flawless operation and performance benchmarks.",
        "image": Mechanical
    },
]

export default originalService