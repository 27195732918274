import Medical from "../../assets/images/medical.jpeg"
import Security from "../../assets/images/security.jpeg"
import Peripheral from "../../assets/images/peripheral.png"



const servicePageData = [
    {
        "name": "Security",
        "title": "Security",
        "id": "security",
        "content": "Securing the Future, Today",
        "description": "Securing the Future, Today",
        "description2": "In a world where digital threats morph by the second, VegaDevX is your unwavering guardian. We're not just a security company; we're your ally in the digital age, ensuring that your data, your devices, and your peace of mind are safeguarded against the unseen.",
        "description3": "Unlock the Future of Security: Imagine a fortress that evolves faster than threats can emerge. That's the kind of protection VegaDevX offers. With us, you're always steps ahead, wrapped in layers of cutting-edge security that's as invisible as it is impenetrable.",
        "description4": "Tailored Armor for Your Digital Life: Every organization is unique, and so are its vulnerabilities. We specialize in creating bespoke security solutions that adapt to you, ensuring your most sensitive assets are protected with precision and ingenuity.",
        "description5": "VegaDevX: A Safer Digital Tomorrow Starts Today.",
        "image": Security
    },
    {
        "name": "Medical & Healthcare",
        "title": "Medical & Healthcare",
        "id": "medical",
        "content": "Innovations that Transform Healthcare",
        "description": "Innovating Healthcare with Medical Technology",
        "description2": "Shaping Tomorrow with Medical Devices: At VegaDevX, our passion for advanced medical technologies—spanning devices, hardware, and software—drives us to develop solutions that address today’s health challenges and unlock future possibilities.",
        "description3": "Join the Technological Revolution: With VegaDevX, you're at the forefront of medical innovation. Our cutting-edge devices and software redefine healthcare, breaking barriers and setting new standards in medical care.",
        "description4": "VegaDevX: Empowering Progress, Together: Be part of our journey as we transform healthcare with innovative medical devices, hardware, and software. Together, we're forging a future where every challenge is an opportunity for advancement.",
        "image": Medical
    },
    {
        "name": "Peripheral Connectivity",
        "title": "Streamlined Hardware Solutions",
        "id": "peripheral",
        "description": "Streamlined Hardware Solutions",
        "description2": "Design & Development: We engineer robust, scalable connectivity modules for seamless integration of peripheral devices, focusing on efficient and versatile connections.",
        "description3": "Testing: Our connectivity solutions undergo extensive testing to guarantee performance and durability in diverse conditions.",
        "description4": "Support: We provide ongoing support, including troubleshooting and updates, ensuring your connectivity systems stay current and effective.",
        "description5": "Optimization: Continuous enhancements minimize latency, maximize bandwidth, and strengthen security, boosting system performance and reliability.",
        "image": Peripheral
    }
]

export default servicePageData