import Box from "@mui/system/Box";
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Container} from "@mui/system";
import abouthero from '../../assets/images/abouthero.jpg'


function AboutHero(props){
    const theme = useTheme()

    return(
        <Box 
            sx={{
                flexgrow: 1, 
                height: {
                    xs: "100vh", 
                    sm: "100vh", 
                    md: "100vh", 
                    lg: "100vh", 
                    xl: "100vh"}, 
                width: "100%",
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(${abouthero})`, 
                backgroundSize: "cover",
                display: "flex",
                alignItems: "center"
            }}
        >
            <Container>
                <Box sx={{ color: "white" }}>
                    <Box>
                        <Typography variant="h1" theme={theme} >
                            About Us
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default AboutHero
