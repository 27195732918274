import { Box, Grid, Typography } from "@mui/material";
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import listings from "../../assets/dataSets/jobListing";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import LocationOnIcon from '@mui/icons-material/LocationOn';
function Careers(){
    return(
        <Box>
            <Container>
                <Typography variant="h4" sx={{ mt: 5, mb: 5}}>
                    Available Offers
                </Typography>
            </Container>
            <Grid container spacing={3} sx={{ mb: 5 }}>
                {listings.map((listing, index) => (
                    <Grid item xs={12} sm={12} md={12} key={index}>
                        <Container>
                            <Box>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h4">
                                            {listing.name}
                                        </Typography>
                                        <br /><br/>
                                        <Typography variant="p"><AccessAlarmIcon /> {listing.jobType}  &nbsp; | &nbsp; <LocationOnIcon /> {listing.location}  &nbsp; | &nbsp; Send your resume to info@vegadevx.com </Typography>         
                                    </CardContent>
                                </Card>
                            </Box>
                        </Container>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default Careers